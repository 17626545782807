import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {Observable, Subject, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {FormProvider} from './form.provider';
import {FormState} from '../models/form-state.interface';
import {Form} from '../models/form.model';

export function initializeApplicationLoader(service: DataLoaderService, provider: FormProvider, store: FormStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class FormStore extends EntityStore<Form, FormState> {
    #created = new Subject<EntitiesChange<Form>>();
    get created(): Observable<EntitiesChange<Form>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Form>>();
    get updated(): Observable<EntitiesChange<Form>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Form>>();
    get deleted(): Observable<EntitiesChange<Form>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectFormsForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, forms) => {
            if (application) {
                return forms.filter(form => form.application?.id === application.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        IS => IS?.nextFormCodeAvailable ?? 1
    );

    /** @ignore */
    private subs = new SubSink();

    // Selectors
    readonly selectAllFormsByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        forms => forms.filter(form => form.features.find(f => f.id === feature.id))
    );

    public override onSuccess(change: EntitiesChange<Form>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Form, FormState> {
        return {
            storeName: 'FormStore',
            initialState: {ids: [], entities: {}},
            selectId: form => form.id,
            sort: sortByCode,
        };
    }

    constructor(provider: FormProvider, private applicationStore: ApplicationStore, private ISStore: InformationSystemStore) {
        super(provider);

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }
}
