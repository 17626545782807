import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {BranchStore} from '@branch/data';
import {ComponentStore} from '@ngrx/component-store';
import {PermissionService} from '@user/security';
import {VersionableState} from '@versionable/data';
import {distinctUntilChanged, map, of, tap} from 'rxjs';

import {ActorStore} from './actor.store';
import {Actor} from '../models/actor.model';

export interface ActorLocalState {
    currentActor?: Actor;
    versionableState: VersionableState;
    formSaveState: boolean;
}

const defaultState: ActorLocalState = {
    versionableState: 'view',
    currentActor: undefined,
    formSaveState: false,
};

@Injectable()
export class ActorLocalStore extends ComponentStore<ActorLocalState> {
    // Synchronous operations. Avoid using them if you can.
    get currentActor(): Actor | undefined {
        return this.get().currentActor;
    }

    // Selectors
    private readonly selectCurrent$ = this.select(state => state.currentActor);
    readonly selectCurrentActor$ = this.select(
        this.store.selectActorsForCurrentIS$,
        this.selectCurrent$,
        // we map the "currentActor" from the actual ActorStore so that we have the latest available data
        (actors, current) => actors.find(actor => actor.id === current?.id)
    );
    readonly selectVersionableState$ = this.select(state => state.versionableState);
    readonly selectSaveState$ = this.select(state => state.formSaveState);
    readonly selectPreview$ = this.route.parent ? this.route.parent.data.pipe(map(data => data['preview'] as boolean ?? false)) : of(false);

    // Updaters
    readonly setCurrentActor = this.updater((state, actor: Actor) => ({
        ...state,
        currentActor: actor,
    }));
    readonly changeVersionableState = this.updater((state, vState: VersionableState) => ({
        ...state,
        versionableState: vState
    }));
    readonly changeSaveState = this.updater((state, sState: boolean) => ({
        ...state,
        formSaveState: sState
    }));

    constructor(
        private route: ActivatedRoute,
        private store: ActorStore,
        private branchStore: BranchStore,
        private permissionService: PermissionService,
    ) {
        super(defaultState);

        // Refreshes the store data every time the URL changes
        this.route.data.pipe(
            takeUntilDestroyed(),
            tap(data => {
                if (data['editMode']) {
                    this.changeVersionableState('edition');
                }
                this.setCurrentActor(data['actor']);
            })
        ).subscribe();

        // No edition on main branch (but exception for super admin)
        this.branchStore.selectIsMainBranch$.pipe(
            takeUntilDestroyed(),
            distinctUntilChanged(),
            tap(isMain => isMain && !this.permissionService.isSuperAdmin() ? this.changeVersionableState('view') : null),
        ).subscribe();
    }
}
