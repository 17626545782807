import {IdType} from '@axiocode/entity';
import {z} from 'zod';

export type Branch = {
    id: IdType;
    name: string;
    reminderOfTheNeed?: string;
    issueLink?: string | null;
    informationSystem: {id: IdType};
    parent?: Branch;
    isMainBranch: boolean;
    mergeAt?: Date;
};

// Validation schema for the form
export const branchSchema = z.object({
    name: z.string().trim().min(1).max(255),
    reminderOfTheNeed: z.string().trim().min(1),
    issueLink: z.string().url().or(z.string().length(0)).nullish(),
});
export type BranchDTO = z.infer<typeof branchSchema>;
