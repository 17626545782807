import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {Observable, Subject, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {NonFunctionalRequirementProvider} from './non-functional-requirement.provider';
import {NonFunctionalRequirementState} from '../models/non-functional-requirement-state.interface';
import {NonFunctionalRequirement} from '../models/non-functional-requirement.model';

export function initializeDataLoader(service: DataLoaderService, provider: NonFunctionalRequirementProvider, store: NonFunctionalRequirementStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class NonFunctionalRequirementStore extends EntityStore<NonFunctionalRequirement, NonFunctionalRequirementState> {
    #created = new Subject<EntitiesChange<NonFunctionalRequirement>>();
    get created(): Observable<EntitiesChange<NonFunctionalRequirement>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<NonFunctionalRequirement>>();
    get updated(): Observable<EntitiesChange<NonFunctionalRequirement>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<NonFunctionalRequirement>>();
    get deleted(): Observable<EntitiesChange<NonFunctionalRequirement>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectNonFunctionalRequirementsForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, requirements) => {
            if (application) {
                return requirements.filter(requirement => requirement.application?.id === application.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        IS => IS?.nextNonFunctionalRequirementCodeAvailable ?? 1
    );

    /** @ignore */
    private subs = new SubSink();

    public override onSuccess(change: EntitiesChange<NonFunctionalRequirement>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<NonFunctionalRequirement, NonFunctionalRequirementState> {
        return {
            storeName: 'NonFunctionalRequirementStore',
            initialState: {ids: [], entities: {}},
            selectId: nonFunctionalRequirement => nonFunctionalRequirement.id,
            sort: sortByCode,
        };
    }

    constructor(provider: NonFunctionalRequirementProvider, private applicationStore: ApplicationStore, private ISStore: InformationSystemStore) {
        super(provider);

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }
}
