import {Actor, ActorLocalStore, ActorStore, formatAPI} from '@actor/data';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ErrorHandler, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {ConfigurationStore} from '@configuration';
import {InformationSystem} from '@information-system/data';
import {MentionConversionService} from '@mention/data';
import {filterOnName, firstOrNull, modelsEqualityById} from '@utils';
import {BehaviorSubject, Subject, delay, map, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {ActorType} from '../../forms/actor.type';

@Component({
    selector: 'actor-form',
    templateUrl: './actor-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActorFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Output() submitted: Subject<Actor> = new Subject<Actor>();
    @Output() isInvalid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    @Input() informationSystem?: InformationSystem = undefined;
    @Input() actor?: Actor = undefined;
    @Input() form: FormGroup;

    nextCodeAvailable$ = this.store.selectNextCodeAvailable$.pipe(
        // We don't want to update the form if the user has already entered a value.
        take(1),
        delay(1),
        tap(value => {
            if (value && !this.actor?.id) {
                this.form.patchValue({code: value});
            }
        })
    );

    private subs = new SubSink();

    isCreateMode = false;
    compare = modelsEqualityById;
    filter = filterOnName;
    inheritableActors: Actor[] = [];
    prefix = '';
    formType: ActorType = new ActorType();

    constructor(
        private store: ActorStore,
        private localStore: ActorLocalStore,
        private configurationStore: ConfigurationStore,
        @Inject(ErrorHandler) private errorHandler: ErrorHandlerService,
        private mentionConversionService: MentionConversionService,
        private cd: ChangeDetectorRef,
    ) {
        this.form = this.formType.getForm();
    }

    /** @ignore */
    ngOnInit(): void {
        this.store.findAll();
        this.configurationStore.load();

        this.subs.sink = this.nextCodeAvailable$.subscribe();

        this.subs.sink = this.store.updated.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                this.submitted.next(entity);
                this.localStore.changeSaveState(false);
            }
        });

        this.subs.sink = this.store.created.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                this.submitted.next(entity);
                this.localStore.changeSaveState(false);
            }
        });

        this.subs.sink = this.store.error.subscribe(error => {
            this.localStore.changeSaveState(false);
            let apiFormError = this.errorHandler.handleError(error);
            if (apiFormError?.formErrors && this.form) {
                this.errorHandler.applyFormErrors(apiFormError.formErrors, this.form);
                this.cd.detectChanges();
            }
        });

        // Check the ID because in the case of duplication, the entity is not updated but created.
        if (this.actor?.id) {
            this.setValue(this.actor);
            this.isCreateMode = false;
        } else {
            this.isCreateMode = true;
        }

        this.subs.sink = this.configurationStore.selectConfigurations$.pipe(
            tap(value => this.prefix = value?.actorCodePrefix as string)
        ).subscribe();

        // Fetch all actors for inheritance (except those which already inherit from the current actor)
        this.store.selectAll$.pipe(
            map(actors => actors.filter(
                value => undefined === this.actor || value.inherits?.id !== this.actor.id && value.id !== this.actor.id
            )),
            map(actors => actors.sort((a, b) => a.name.localeCompare(b.name)))
        ).subscribe(actors => this.inheritableActors = actors);
    }

    /** @ignore */
    ngAfterViewInit(): void {
        // Invalid changes
        this.subs.sink = this.form.statusChanges.subscribe(() => this.isInvalid.next(this.form.invalid));
    }

    /** @ignore */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['actor'] && this.actor) {
            if ('edition' !== this.localStore.state().versionableState) {
                this.setValue(this.actor);
            }
            // If the new entity is different than the previous one, we change the state to "view".
            if (changes['actor'].previousValue && changes['actor'].currentValue?.id !== changes['actor'].previousValue?.id) {
                this.localStore.changeVersionableState('view');
            }
        }
    }

    /** @ignore */
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    validate(): void {
        if (this.informationSystem && this.form.valid) {
            const data = this.getValue();

            if (this.isCreateMode) {
                this.store.create(formatAPI(data));
            } else {
                this.store.update({data: formatAPI(data)});
            }
            this.localStore.changeSaveState(true);
        }
    }

    setValue(actor: Actor): void {
        this.form.patchValue(actor);
        this.mentionConversionService.convertDescriptionMention(actor.description).pipe(take(1)).subscribe(
            value => {
                if (!this.form.get('description')?.touched) {
                    this.form.patchValue({description: value});
                }
            }
        );
    }

    getValue(): Actor {
        const data = this.form.getRawValue();
        // Need to force the id because of the actor.model update.
        // This change is linked to the ability of removing inheritance.
        if (data.inherits) {
            data.inherits = {
                id: data.inherits.id
            };
        }
        if (this.actor) {
            data.id = this.actor.id;
        }
        if (this.informationSystem) {
            data.informationSystem = this.informationSystem;
        }

        return data;
    }
}

