import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {sortByCode} from '@utils';
import {Observable, Subject, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {FunctionalRequirementProvider} from './functional-requirement.provider';
import {FunctionalRequirementState} from '../models/functional-requirement-state.interface';
import {FunctionalRequirement} from '../models/functional-requirement.model';

export function initializeDataLoader(service: DataLoaderService, provider: FunctionalRequirementProvider, store: FunctionalRequirementStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class FunctionalRequirementStore extends EntityStore<FunctionalRequirement, FunctionalRequirementState> {
    private applicationStore = inject(ApplicationStore);
    private ISStore = inject(InformationSystemStore);

    #created = new Subject<EntitiesChange<FunctionalRequirement>>();
    get created(): Observable<EntitiesChange<FunctionalRequirement>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<FunctionalRequirement>>();
    get updated(): Observable<EntitiesChange<FunctionalRequirement>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<FunctionalRequirement>>();
    get deleted(): Observable<EntitiesChange<FunctionalRequirement>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectFunctionalRequirementsForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, requirements) => {
            if (application) {
                return requirements.filter(requirement => requirement.application?.id === application.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        IS => IS?.nextFunctionalRequirementCodeAvailable ?? 1
    );

    /** @ignore */
    private subs = new SubSink();

    // Selectors
    readonly selectAllFuncReqsByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        funcReqs => funcReqs.filter(funcReq => funcReq.features?.find(f => f.id === feature.id))
    );

    public override onSuccess(change: EntitiesChange<FunctionalRequirement>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<FunctionalRequirement, FunctionalRequirementState> {
        return {
            storeName: 'FunctionalRequirementStore',
            initialState: {ids: [], entities: {}},
            selectId: functionalRequirement => functionalRequirement.id,
            sort: sortByCode,
        };
    }

    constructor(provider: FunctionalRequirementProvider) {
        super(provider);

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }
}
