import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {BranchStore} from '@branch/data';
import {ComponentStore} from '@ngrx/component-store';
import {PermissionService} from '@user/security';
import {VersionableState} from '@versionable/data';
import {distinctUntilChanged, map, of, tap} from 'rxjs';

import {DataModelStore} from './data-model.store';
import {DataModel} from '../models/data-model.model';

export interface DataModelLocalState {
    currentDataModel?: DataModel;
    versionableState: VersionableState;
    dataModelSaveState: boolean;
}

const defaultState: DataModelLocalState = {
    versionableState: 'view',
    currentDataModel: undefined,
    dataModelSaveState: false,
};

@Injectable()
export class DataModelLocalStore extends ComponentStore<DataModelLocalState> {
    // Synchronous operations. Avoid using them if you can.
    get currentDataModel(): DataModel | undefined {
        return this.get().currentDataModel;
    }
    // Selectors
    private readonly selectCurrent$ = this.select(state => state.currentDataModel);
    readonly selectCurrentDataModel$ = this.select(
        this.store.selectDataModelsForCurrentIS$,
        this.selectCurrent$,
        // we map the "currentDataModel" from the actual DataModelStore so that we have the latest available data
        (dataModels, current) => dataModels.find(dataModel => dataModel.id === current?.id)
    );
    readonly selectPreview$ = this.route.parent ? this.route.parent.data.pipe(map(data => data['preview'] as boolean ?? false)) : of(false);
    readonly selectVersionableState$ = this.select(state => state.versionableState);
    readonly selectSaveState$ = this.select(state => state.dataModelSaveState);

    // Updaters
    readonly setCurrentDataModel = this.updater((state, dataModel: DataModel) => ({
        ...state,
        currentDataModel: dataModel,
    }));

    readonly changeVersionableState = this.updater((state, vState: VersionableState) => ({
        ...state,
        versionableState: vState
    }));
    readonly changeSaveState = this.updater((state, sState: boolean) => ({
        ...state,
        dataModelSaveState: sState
    }));

    constructor(
        private route: ActivatedRoute,
        private store: DataModelStore,
        private branchStore: BranchStore,
        private permissionService: PermissionService,
    ) {
        super(defaultState);

        // Refreshes the store data every time the URL changes
        this.route.data.pipe(
            takeUntilDestroyed(),
            tap(data => {
                if (data['editMode']) {
                    this.changeVersionableState('edition');
                }
                this.setCurrentDataModel(data['dataModel']);
            })
        ).subscribe();

        // No edition on main branch (but exception for super admin)
        this.branchStore.selectIsMainBranch$.pipe(
            takeUntilDestroyed(),
            distinctUntilChanged(),
            tap(isMain => isMain && !this.permissionService.isSuperAdmin() ? this.changeVersionableState('view') : null),
        ).subscribe();
    }
}
