import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {DataModelStore} from '@data-model/data';
import {FormStore} from '@form/data';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {TableStore} from '@table/data';
import {firstOrNull, sortByCode} from '@utils';
import {Observable, Subject, merge, switchMap, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {PageProvider} from './page.provider';
import {PageState} from '../models/page-state.interface';
import {Page} from '../models/page.model';

export function initializeDataLoader(service: DataLoaderService, provider: PageProvider, store: PageStore) {
    return () => service.registerLoader(() => {
        provider.resetCache();
        store.reset();

        return provider.findAll$().pipe(tap(data => store.setMany(data)));
    });
}

@Injectable({providedIn: 'root'})
export class PageStore extends EntityStore<Page, PageState> implements OnDestroy {
    #created = new Subject<EntitiesChange<Page>>();
    get created(): Observable<EntitiesChange<Page>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Page>>();
    get updated(): Observable<EntitiesChange<Page>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Page>>();
    get deleted(): Observable<EntitiesChange<Page>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectPagesForCurrentApplication$ = this.select(
        this.applicationStore.selectSelectedEntity$,
        this.selectAll$,
        (application, pages) => {
            if (application) {
                return pages.filter(page => page.application?.id === application.id);
            }

            return [];
        }
    );
    readonly selectNextCodeAvailable$ = this.select(
        this.ISStore.selectSelectedEntity$,
        IS => IS?.nextNonFunctionalRequirementPageCodeAvailable ?? 1
    );

    /** @ignore */
    private subs = new SubSink();

    // Selectors
    readonly selectAllPagesByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        pages => pages.filter(page => page.features.find(f => f.id === feature.id))
    );

    public override onSuccess(change: EntitiesChange<Page>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Page, PageState> {
        return {
            storeName: 'PageStore',
            initialState: {ids: [], entities: {}},
            selectId: page => page.id,
            sort: sortByCode,
        };
    }

    constructor(
        provider: PageProvider,
        private applicationStore: ApplicationStore,
        private ISStore: InformationSystemStore,
        private dataModelStore: DataModelStore,
        private tableStore: TableStore,
        private formStore: FormStore,
    ) {
        super(provider);

        this.subs.sink = this.dataModelStore.created.subscribe(change => handleRelatedStoreCreated(this, change));
        this.subs.sink = this.tableStore.created.subscribe(change => handleRelatedStoreCreated(this, change));
        this.subs.sink = this.formStore.created.subscribe(change => handleRelatedStoreCreated(this, change));

        // Refreshes the next code available
        this.subs.sink = merge(this.#created, this.#updated, this.#deleted).pipe(
            switchMap(() => this.ISStore.refreshCurrentIS$),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subs.unsubscribe();
    }
}

const handleRelatedStoreCreated = <T>(store: PageStore, change: {entities: T[]}) => {
    const entity = firstOrNull(change.entities);
    if (entity) {
        // If a page has been generated from the model, we need to refresh the page store.
        store.reset(); // Ensure we don't have HTTP cache
        store.findAll();
    }
};
